<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ doc.name }}
    </h5>

    <a-input
      field="name"
      type="text"
    />

    <a-input
      field="description"
      type="textarea"
    />
  </div>
</template>

<script>
import formMixin from './formMixin'

export default {
  mixins: [formMixin]
}
</script>
